/* make the customizations */
$theme-colors: ("primary": #6851ff,
    "info": #61DBFB,
    "black":black,
    "secondary": #a2afb994,
    "orange": #fc6e47,
    "macaroni":#ffd021,
    "darkBlue":#0d244d,
);

html,
body,
#root,
.App {
    height    : 100%;
    width     : 100%;
    overflow-x: hidden;
}

.App {
    background-color: #e6e7ed;
}

/* File Drop CSS */

.file-drop{
    cursor: pointer;
}

.file-drop>.file-drop-target.file-drop-dragging-over-frame {
    /* overlay a black mask when dragging over the frame */
    border          : none;
    box-shadow      : none;
    z-index         : 50;
    opacity         : 1;

    /* typography */
    color: white;
}

.file-drop>.file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    color     : #6851ff;
    box-shadow: 0 0 8px 3px #6851ff;
}


/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";